import { template as template_ae78eae68a6643a0a7b5723894850a6c } from "@ember/template-compiler";
const WelcomeHeader = template_ae78eae68a6643a0a7b5723894850a6c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
