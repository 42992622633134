import { template as template_3f3ec17f86444e668a02b6aa7ba9d5ea } from "@ember/template-compiler";
const FKLabel = template_3f3ec17f86444e668a02b6aa7ba9d5ea(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
