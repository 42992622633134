import { template as template_0a7154f238d74b0fb30c9ef8fd9f4f91 } from "@ember/template-compiler";
const FKControlMenuContainer = template_0a7154f238d74b0fb30c9ef8fd9f4f91(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
