import { template as template_6fce964b113a4fc7b0ed6f0d407d5f70 } from "@ember/template-compiler";
const SidebarSectionMessage = template_6fce964b113a4fc7b0ed6f0d407d5f70(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
